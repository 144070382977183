<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <!-- <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择位类型分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item> -->
        <el-form-item class="generate">
          <el-button type="primary" @click="toAddAdvert"
            >添加商品</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="goodsList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="75"
          align="center"
        ></el-table-column>
       
        <el-table-column label="课程名" prop="title" align="center"></el-table-column>
        <el-table-column label="类型" prop="types" align="center"></el-table-column>
        <el-table-column label="封面" align="center">
          <template slot-scope="scope">
            <div @click="openThumb(scope.row.cover)" class="thumb_box" v-if="scope.row.cover">
                <img :src="scope.row.cover" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price" align="center"></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toEditAdvert(scope.row)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 缩略图 -->
    <el-dialog title="缩略图详情" :visible.sync="detailsDialog" :center=true width="40%">
      <div style="width:100%;height:100%">
        <img style="width:100%;height:100%" :src="detailsImg" alt="">
      </div>
    </el-dialog>

    <!-- 添加商品 -->
    <el-dialog :title="isAdd?'添加商品':'编辑商品'" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="课程名" label-width="100px">
            <el-input v-model="addForm.title" placeholder="请输入课程名"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="分类" label-width="100px">
          <el-select
            v-model="addForm.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="封面图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
              style="width:260px;height:260px"
            >
                <img
                  v-if="thumbSrc"
                  style="width: 100%"
                  :src="thumbSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
        </el-form-item>
        <el-form-item el-form-item label="价格" label-width="100px">
            <el-input placeholder="请输入价格，例如99.99" v-model="addForm.price"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="详情" label-width="100px">
            <el-card style="height: 680px;">
                <quill-editor v-model="addForm.content" ref="myQuillEditor" style="height: 500px;" :options="editorOption"></quill-editor>
            </el-card>
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editNow" >立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
var COS = require("cos-js-sdk-v5");
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  AdvertCosToken
} from "@/api/advertisingList.js";
import {
  payGoodsList,
  addPayGoods,
  updatePayGoods
} from "@/api/goodsList.js";
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      queryForm: {
        type: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      goodsList: [],
      typeArr: [
        { key: 1, value: "日语" },
        { key: 2, value: "韩语" },
        { key: 3, value: "法语" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        type: "",
        title: "",
        cover: "",
        price: "",
        content: "",
      },
      // addFormTimes: null,
      thumbSrc: "",
      detailsImg: "",
      detailsDialog: false,
      isAdd: true,
      editorOption: {}
    };
  },
  created() {
    this.getGoodsList();
  },

  methods: {
    openThumb(src) {
      this.detailsDialog = true;
      this.detailsImg = src;
    },
    // 获取商品列表
    getGoodsList() {
      payGoodsList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.goodsList = res.data.data;
        this.goodsList.forEach(item=>{
          if(item.type == 1){
            item.types = "日语"
          }else if(item.type == 2){
            item.types = "韩语"
          }else if(item.type == 3){
            item.types = "法语"
          }
          item.price = Number(item.price)/100
        })
      });
    },

    // 点击添加商品按钮
    toAddAdvert() {
      this.isAdd = true;
      this.addDialog = true;
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      AdvertCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.cover = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 添加任务
    addNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入课程名");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.cover == null) {
        this.$message.error("请上传封面图");
        return;
      } else if (this.addForm.price == "") {
        this.$message.error("请输入价格");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请上传详情内容");
        return;
      }
      this.addForm.price = Number(this.addForm.price)*100
      addPayGoods(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加商品成功");
        this.addDialog = false;
        this.getGoodsList();
      });
    },
    // 点击编辑按钮
    toEditAdvert(row) {
      this.isAdd = false;
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(row));
      this.thumbSrc = this.addForm.cover;
    },

    // 点击立即修改按钮
    editNow() {
      if (this.addForm.title == "") {
        this.$message.error("请输入课程名");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.cover == null) {
        this.$message.error("请上传封面图");
        return;
      } else if (this.addForm.price == "") {
        this.$message.error("请输入价格");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请上传详情内容");
        return;
      }
      if(this.addForm.cover.indexOf(".com/") != -1){
        this.addForm.cover = this.addForm.cover.split("?")[0].split(".com/")[1]
      }
      this.addForm.price = Number(this.addForm.price)*100
      updatePayGoods(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑商品成功");
        this.addDialog = false;
        this.getGoodsList();
      });
    },
    // 弹窗关闭事件
    closed() {
      this.addForm = {
        type: "",
        title: "",
        url: "",
        thumb: "",
        display: "",
        width: "",
        height: "",
        play_time_start: "",
        play_time_end: "",
      };
      this.thumbSrc = "";
      this.addFormTimes = null;
    },

    //  查询
    toQuery() {
      if (this.queryForm.display === "") {
        this.queryForm.display = null;
      }
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getAdvertList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getAdvertList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getAdvertList();
    },
  },
};
</script>

<style lang="less" scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 160px;
    height: 90px;
  }
}

.tips {
  color: red;
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
