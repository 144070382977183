import request from "@/utils/request";

// 获取商品列表
export function payGoodsList(data) {
  return request({
      url: "/admin/base/payGoodsList",
      method: "get",
      params: data
  });
}
// 添加商品
export function addPayGoods(data) {
  return request({
      url: "/admin/base/addPayGoods",
      method: "post",
      data
  });
}
// 修改商品
export function updatePayGoods(data) {
  return request({
      url: "/admin/base/updatePayGoods",
      method: "post",
      data
  });
}